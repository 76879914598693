.card-image {
    object-fit: cover;
	border-radius: 6px;
	-webkit-box-shadow:0 10px 38px 10px rgba(0, 0, 0, 0.1);
	-moz-box-shadow:  0 10px 38px 10px rgba(0, 0, 0, 0.1);
	box-shadow: 0 10px 38px 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
    width: 100%;
	height: 238px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 2em 1em;
}
.card-text {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #F8F5FF;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 768px){    
    .card__button{
        font-size: smaller;
        border-radius: 10px;
        border: 0;
    }  
    .modal__image {
        height: 10em;
    }
}


@media (min-width:768px) {
    .card__button{
        font-size:medium;
        border-radius: 5px;
        border: 0;
    }
}

.modal__container{
    display: flex;
    width: 100%;
    flex:1;
    flex-direction: column;
    overflow: auto;
    max-height: 70vh;
}
.title-card-modal{
    font-weight: 400;
    font-size: 1.5em;
}
.modal__image{
    min-height: 15em;
    border-radius: 15px;
    margin-bottom: 0.7em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: 0px 4px 4px  rgba(0, 0, 0, 0.25);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 4px 4px  rgba(0, 0, 0, 0.25);  /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.recipe-modal .ant-modal .ant-modal-content .ant-modal-header {
    padding: 0;
    color: transparent;
    border-bottom: 0px;
}

.recipe-modal .ant-modal .ant-modal-content .ant-modal-body {
    padding: 12px;
}



.row-footer {
     background: #F6F6F6; 
     border-radius: 10px;
     padding: 15px 10px ;
     display: flex;
     justify-content: center;
     margin: 1em 0;
}

.footer-modal-item {
    display: flex;
    flex-direction: column;
    margin-right: 0.8em;
    background: white;
    border-radius: 0.5em;
    padding: 0.5em;
    cursor: pointer;
}