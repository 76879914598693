@media (max-width: 768px){
    .main__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .modalRestriction__container--info{
        padding: 20px;
    }
    .modalRestriction__container--image{
        display: none;
    }
    .content__container--headerDesk{
        display: none;
    }
    .content__container--headerMobile{
        display: flex;
        flex-direction: row;
        width: 100%;
        text-align: center;
        
    }
    .content__container--headerMobile{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        
    }
    
    

}
@media (min-width: 768px){
    .main__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .modalRestriction__container--image{
        width: 62%;
        margin: 0;
        padding: 0;
        background-color:rgb(254, 244, 173);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        
    }
    .modalRestriction__container--image h1{
        font-size: 1.8rem;
    }
    .modalRestriction__container--info{
        width: 38%;
        padding: 30px;
    }
    .modalRestriction__container--info h3{
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
    .modalRestriction__container--info p{
        font-size: 1rem;
        margin-bottom: 2rem;
    }
    .modalRestriction__container--benefits p{
        font-size: 0.875rem;
        margin-bottom: 1rem;
    }
    .modalRestriction__container--button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content__container--headerMobile{
        display: none;
    }
    .content__container--headerDesk{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        
        
    }
    .content__container--headerDesk div{
        display: flex;
        width: 33.33%;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        
    }
}
.content h1{
    color: #ED4049;
    font-weight: bolder;
}
.button__custom{
    margin-top: 15px;
}
.ant-card-bordered{
    margin-bottom: 10px;
}
.modalRestriction__image{
    width: 50%;
}