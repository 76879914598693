.create-account-modal {
    position: fixed;
    bottom: 0px;
    top: auto;
    margin-bottom: 0px;
    padding: 0px;
    width: 100vw;
    max-width: 100vw;
  
  }

 .create-account-modal__form {
    color: white;
    margin-bottom: 6rem; 
  }

@media screen and (max-width: 375px) {
   .create-account-modal__form {
        margin-bottom: 3rem;
    }
  }

 .create-account-modal__form  label {
    color: white;
    margin-left: 6px;
    margin-bottom: -15px;
    display: block;
  }


 .create-account-modal__form   button {
    height: 52px;
  }
  
  .create-account-modal .ant-modal-content {
    background: linear-gradient(90deg, #0c4db7 -3.33%, #2c126b 103.49%);
    border-radius: 0px 140px 0px 0px;
  }

  .create-account-modal .create-account-modal__top-info {
    color: white;
    margin-bottom: 2.5rem;
  }

 .create-account-modal__top-info  img {
    width: 44px;
    height: auto;
    margin-bottom: 1.5rem;
  }
 
.create-account-modal__top-info  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: white;
  }
 
  .create-account-modal .create-account-modal__top-info  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }