
.header-container {
    width: 100%;
    display: flex; 
    align-items: center; 
    justify-content: flex-start;
}
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
}
.logo{
    width: 1.8em;
    margin: 0 20px 0 60px;
    
}

.title{
    margin-top: 8px;
}




@media only screen and (max-width: 576px) {
    .header-container {
        justify-content: space-between;
        padding: 0 28px 0 12px
    }
 
    .hide {
        display: none;
    }
   .logo {
    width: 25px;
    margin: 0;
   }
   .title {
    display: none;
   }
}