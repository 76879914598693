.segment-container {
   width: 100%; 
   margin: 0 0 2em 0; 
}

.segment-controller {
  border-left: 1px solid #d2ddfa;
  border-radius: 90px;
  background: #D2DDFA;
  flex: 1;
  max-width: 340px;
}

.control {
  cursor: pointer;
  padding: 8px 25px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
}

.control-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-transition {
  background-color: #EEE;
	  -webkit-transition: 
		background-color 380ms linear,
			   color 380ms linear;
	
}


@media only screen and (max-width: 576px) {
  .segment-container {
    width: 100%; 
    margin: 0 0 2em 0; 
 } 
}