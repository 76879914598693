:root {
  --blue-primary: rgb(36, 132, 249);
}

table tr:nth-child(odd) td{
  background-color: #F6F6F6;
  padding-left: 10px;
}
table tr:nth-child(even) td{
  background-color: white;
  padding-left:  10px;
}

@media (max-width: 768px) {
  .table {
    width: 100%;
  }
  .button__container--generate {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .button__container {
    margin-top:  1em;
    width: 80%;
  }
  .carrousel__container {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-bottom: 30px;
    overflow-y: scroll;
  }
  .carrousel__card {
    display: flex;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .carrousel__card--image {
    max-width: 150px;
    margin-bottom: 10px;
  }
  .icon-control {
    font-size: 12px;
  }

}

@media (min-width: 768px) {
  .table {
    width: 65%;
  }
  .button__container--generate {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    width: 40%;
  }
  .button_add_group {
    border-radius: 10px;
  }
  .button__container {
    width: 40%;
    margin-top: 1em;
    
  }
  .carrousel__container {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .carrousel__card {
    width: 20%;
  }
  .carrousel__card--image {
    width: 100%;
    margin-bottom: 10px;
  }
  .icon-control {
    font-size: 14px;
  }

}

th {
  width: 50%;
}
.input__portions {
  width: 120px;
  align-self: center;
  border-radius: 5px;
}
.table__input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}
th {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.495);
  padding-bottom: 10px;
}
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
}
.button__container {
  display: flex;
  justify-content: space-between;
}
.label__totalKCal {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.button__custom {
  width: 100%;
  border-radius: 10px;
  min-width: 100px;
  min-height: 30px;
  margin-bottom: 60px;
}



.contact__container {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-bottom: 60px;
  align-items: center;
  text-align: center;
}

