@-moz-keyframes fadeIn {
    from {opacity:0;}
    to {opacity:1;}
}

@-webkit-keyframes fadeIn {
    from {opacity:0;}
    to {opacity:1;}
}

@keyframes fadeIn {
    from {opacity:0;}
    to {opacity:1;}
}
.background-banner-desktop {
    background-image: url("../assets/images/banner01.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 80px;
	display: flex;
	justify-content:space-between;
    padding: 16px;
    color: white;
	align-items: center;
    -webkit-animation: fadeIn 2000ms;
    -moz-animation: fadeIn 2000ms;
    animation: fadeIn 2000ms;
    z-index: 1;
}

.banner-text {
    color: white;
    font-size: 0.75rem;
    max-width: 70%;
    margin-bottom: 0;
}

.banner__container--gradient {
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 80px;
    background: linear-gradient(90deg, #0c4db7 0%, #2c126b 100%);

}

.text-banner-gradient {
    color: #f8f5ff !important;
}

@media only screen and (max-width: 576px) {
    .banner__container--gradient {
        height: 70px;
        padding: 0 20px;
    }
    .card__button{
        font-size: smaller;
        border-radius: 10px;
        border: 0;
    }  
}