.create-account-page {
    width: 100vw;
	height: 100%;
	min-height: 100vh;
	background-image: url("../assets//images/banner02@3x.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}